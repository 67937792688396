import { Atom } from ":mods";
import { FoundationSignupViewProps } from "./model";
import { createSignal, splitProps } from "solid-js";

export function FoundationSignup(props:FoundationSignupViewProps) {
  const [local, others] = splitProps(props, ["data"]);
  const radioContainerStyle = "flex gap-10px border border-2 border#p border-solid p-20px";
  const dropdownContainerStyle = "flex justify-between border border-2 border#p border-solid p-15px";
  const textInputStyle = "p-15px border border-2px border#p border-solid";
  const [moduleOpen, setModuleOpen] = createSignal(false);
  const handleAccordionClicks = () => {
    setModuleOpen(moduleOpen()?false:true);
  };
  
  return (
    <Atom.Foldable.Accordion
      arrowMode="end"
      iconClass={`w-24px h-24px  icon-local:down-arrow ${moduleOpen() && "!rotate-180"}`}
      onclick={handleAccordionClicks}
      title={
        <section class="flex flex-row justify-start items-center w-full font-700 select-none">
          <span>Personal Information (Foundation Sign Up)</span>
        </section>
      }
      class="border-2 border-solid border#p px-2 py-20px mb-20px"
    >
      <section class="flex flex-col gap-20px mt-40px">
        <div class="flex flex-col gap-10px">
          <p class="text-16px leading-22.4px">Full Name</p>
          <div class={textInputStyle}>{local.data.full_name}</div>
        </div>

        <div class="flex flex-col gap-10px">
          <p>Gender</p>
          <div class={dropdownContainerStyle}>
            <p class="capitalize">{local.data.gender}</p>
            <i class="icon-local:down-arrow w-25px h-25px" />
          </div>
        </div>
        <div class="flex flex-col gap-10px">
          <p>Date of Birth</p>
          <div class={dropdownContainerStyle}>
            <p>{local.data.dob}</p>
            <i class="icon-local:down-arrow w-25px h-25px" />
          </div>
        </div>

        <div class="flex flex-col gap-10px">
          <p class="text-16px leading-22.4px">Phone Number</p>

          <div class={`${textInputStyle} flex gap-20px p-15px border-2`}>
            <div class="flex">
              <p>{local.data.phone.slice(0,4)}</p>
              <i class="icon-local:down-arrow w-25px h-25px" />
            </div>
            <p>(0) {local.data.phone.slice(4)}</p>
          </div>
        </div>
        <div class="flex flex-col gap-10px">
          <p class="text-16px leading-22.4px">Do you work for the ministry of culture? :</p>
          <div class={radioContainerStyle}>
            <input type="radio" readOnly checked={local.data.work_in_ministry} class={"w-25px h-25px cursor-pointer"} />
            <p>Yes</p>
          </div>
          <div class={radioContainerStyle}>
            <input type="radio" readOnly checked={!local.data.work_in_ministry} class={"w-25px h-25px cursor-pointer"} />
            <p>No</p>
          </div>
        </div>

        <div class="flex flex-col gap-10px">
          <p class="text-16px leading-22.4px">Please tell us which commission you work for :</p>
          <div class={textInputStyle}>{local.data.commission || "-"}</div>
        </div>
        <div class="flex flex-col gap-10px">
          <p class="text-16px leading-22.4px">What is your Ministry of Culture email address? :</p>
          <div class={textInputStyle}>{local.data.ministry_email || "-"}</div>
        </div>
      </section>
    </Atom.Foldable.Accordion>
  );
}
